import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionStorageService } from '@eui/core';
import { AWSAuthService, xOperatorId } from '@rtpd/core';
import { GrowlHelper } from '../services/growl-helper.service';

export const forwardUrlKey = 'rtpd-forward-url';
export const forwardOperatorIdKey = 'rtpd-forward-operator-id';

@Injectable({ providedIn: 'root' })
export class AWSAuthGuard implements CanActivate {
    private readonly registrationUrlKey = 'rtpd-registration-redirect';

    public constructor(
        private router: Router,
        private growlHelper: GrowlHelper,
        private authService: AWSAuthService,
        private storageService: SessionStorageService,
    ) {}

    public canActivate(_, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this.authService.isEnabled() && !this.authService.isAuthenticated()) {

            if (!this.authService.isAuthCallback(state.url)) {
                if (this.isRegistrationUrl(state.url)) {
                    this.setRegistrationUrl(state.url);
                } else {
                    this.setForwardUrl(state.url);
                }
            }

            return this.authService.signIn().pipe(
                map(() => {
                    if (this.authService.isAuthCallback(state.url) || this.isRegistrationUrl(state.url)) {
                        let url = this.popRegistrationUrl();
                        if (!url) {
                            url = this.authService.removeQueryStrings(state.url);
                        }
                        return this.router.parseUrl(url);
                    }
                    return this.router.parseUrl('/home');
                }),
                catchError(error => {
                    const navigation = this.router.getCurrentNavigation();
                    this.accessDenied(error, navigation?.extras.state?.reload);
                    return of(false);
                }),
            );
        } else {
            return of(true);
        }
    }

    private isRegistrationUrl(url: string): boolean {
        return ['/registration', '/account/create'].some(path => url?.includes(path));
    }

    private setRegistrationUrl(url: string) {
        this.storageService.set(this.registrationUrlKey, url);
    }

    private setForwardUrl(url: string) {
        const params = new URLSearchParams(url.split('?')?.[1]);
        if (params.has(xOperatorId)) {
            const xOperatorIdParam = params.get(xOperatorId);
            this.storageService.set(xOperatorId, xOperatorIdParam);
            this.storageService.set(forwardOperatorIdKey, xOperatorIdParam);
        }
        this.storageService.set(forwardUrlKey, this.authService.removeQueryStrings(url));
    }

    private popRegistrationUrl(): string | undefined {
        const url = this.storageService.get(this.registrationUrlKey);
        this.storageService.remove(this.registrationUrlKey);
        return url;
    }

    private accessDenied(error: any, reload: boolean) {
        this.growlHelper.growlError(`Access is denied: ${error && (error.message || JSON.stringify(error))}`);
        console.error(`Access is denied: ${error && (error.message || JSON.stringify(error))}`);
        if (reload) {
            this.router.navigate(['403'], { state: { reload: true }, queryParams: { reload: true } });
        } else {
            this.router.navigate(['403']);
        }
    }
}
