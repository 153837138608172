import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SearchFilters } from '../../models/search';
import { hasInputChanged } from '@rtpd/shared';
import { I18nService } from '@eui/core';

@Component({
    selector: 'rtpd-term-search',
    templateUrl: './term-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermSearchComponent implements OnChanges, OnDestroy, OnInit {
    @Input() public executeSearch = true;
    @Input() public enabled = true;
    @Input() public term: string;
    @Input() public filters: SearchFilters;
    @Input() public refData: any;
    @Input() public placeholderLabel: string;
    @Input() public buttonLabel: string;
    @Input() public advancedFiltersConfig = {};
    @Input() public isVisible = true;
    @Input() public isAdvancedFiltersOpen = false;
    @Input() public isAdvancedFiltersApplied = false;

    @Output() public search = new EventEmitter<SearchFilters>();
    @Output() public searchToggle = new EventEmitter<boolean>();
    @Output() public termChange = new EventEmitter<string>();

    public hasAdvancedFilters = false;

    @ContentChild('advancedFilters')
    public advancedFiltersRef: TemplateRef<any>;

    public form: FormGroup;

    private initialized: boolean = false;
    private onDestroy$: Subject<void> = new Subject<void>();

    public constructor(
        private fb: FormBuilder,
        protected i18nService: I18nService,
    ) {
        this.form = this.fb.group({
            term: null,
        });
    }

    public ngOnInit() {
        this.initialized = true;
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.changesAdvancedFiltersConfig(changes);
        this.changesFilters(changes);
        this.changesTerm(changes);
    }

    public get isTermSearchVisible(): boolean {
        return (this.hasAdvancedFilters && this.isAdvancedFiltersOpen) || !this.hasAdvancedFilters;
    }

    public find() {
        this.search.emit({ ...this.form.value.advancedFilters, term: this.form.value.term });
    }

    public onTermChange() {
        this.termChange.emit(this.form.value.term);
    }

    public reset() {
        this.resetTermSearch();
        this.resetAdvancedFilter();
    }

    private resetAdvancedFilter() {
        this.form.patchValue({
            advancedFilters: { ...this.advancedFiltersConfig },
        });
    }

    private resetTermSearch() {
        this.form.patchValue({
            term: null,
        });
        this.onTermChange();
    }

    private changesTerm(changes: SimpleChanges) {
        if (hasInputChanged(changes.term, true)) {
            this.form.controls.term.setValue(this.term);
        }
    }

    private changesFilters(changes: SimpleChanges) {
        if (hasInputChanged(changes.filters)) {
            const formValue = { term: this.filters?.term };
            if (this.hasAdvancedFilters) {
                formValue['advancedFilters'] = { ...this.advancedFiltersConfig, ...this.filters };
                if (formValue['advancedFilters']?.term) {
                    delete formValue['advancedFilters'].term;
                }
            }
            this.form.patchValue(formValue);
        }
    }

    private changesAdvancedFiltersConfig(changes: SimpleChanges) {
        let searchExecuted = false;
        if (hasInputChanged(changes.advancedFiltersConfig)) {
            this.form.addControl('advancedFilters', this.fb.group(this.advancedFiltersConfig));
            this.form.get('advancedFilters').valueChanges.pipe(
                distinctUntilChanged((f1, f2) => {
                    return JSON.stringify(f1) === JSON.stringify(f2);
                }),
                takeUntil(this.onDestroy$),
            ).subscribe((filters: SearchFilters) => {
                if (this.executeSearch) {
                    this.search.emit({ ...filters, term: this.form.value.term });
                    searchExecuted = true;
                } else {
                    this.executeSearch = true;
                }
            });
            this.hasAdvancedFilters = true;
        }

        const isTermReset = this.form.get('term').value === null;
        const isTermEmpty = !this.term;
        const isFiltersTermPresent = !!this.filters?.term;
        const searchReload = !searchExecuted && isTermReset && isTermEmpty && isFiltersTermPresent;

        if (hasInputChanged(changes.term, true)  &&  searchReload) {
            setTimeout(() => {
                this.search.emit({ ...this.form.value.advancedFilters, term: null });
            });
        }
    }

    public ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
