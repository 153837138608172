<div class="notranslate">
    <eui-app>
        <eui-app-top-message *ngIf="isTraining" color="error" [isCloseable]="true">
            <h5 [innerHtml]= "'screens.landing.top.message.training.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-header>
            <eui-header>
                <eui-header-logo></eui-header-logo>
                <eui-header-environment *ngIf="nonProduction">{{ envName }}</eui-header-environment>
                <eui-header-app>
                    <eui-header-app-name class="appname">{{ 'application.title.full' | translate }}
                    </eui-header-app-name>
                </eui-header-app>
            </eui-header>
        </eui-app-header>

        <eui-app-toolbar>
            <eui-toolbar>
                <eui-toolbar-logo></eui-toolbar-logo>
            </eui-toolbar>
        </eui-app-toolbar>

        <eui-app-page-wrapper class="no-flex">
            <ng-container *ngIf="!reloading && !isRegistrationProcess; else forwarding">
                <eui-page-header label="{{ 'screens.403.title' | translate }}">
                </eui-page-header>
                <div class="eui-page-header" ></div>
                <eui-block-content>
                    <div class="section-content">
                        <eui-card >
                            <eui-card-header hasHeaderClickToggle="true">
                                <eui-card-header-title>
                                    <h2>{{ 'screens.403.message.title' | translate }}:</h2>
                                </eui-card-header-title>
                            </eui-card-header>
                            <eui-card-content>
                                <ul>
                                    <li>
                                        <h3>1. {{ 'screens.403.message.1' | translate }}</h3>
                                        <ul euiList>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.1.1'  | translate }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                &nbsp;
                                <ul>
                                    <li>
                                        <h3>2. {{ 'screens.403.message.2' | translate }}</h3>
                                        <ul euiList>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.2.1'  | translate }}
                                            </li>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.2.2'  | translate }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                &nbsp;
                                <ul>
                                    <li>
                                        <h3>3. {{ 'screens.403.message.3' | translate }}</h3>
                                        <ul euiList>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.3.1'  | translate }}
                                            </li>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.3.2'  | translate }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                &nbsp;
                                <ul>
                                    <li>
                                        <h3>4. {{ 'screens.403.message.4' | translate }}</h3>
                                        <ul euiList>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.4.1'  | translate }}
                                            </li>
                                            <li euiListItem>
                                                - {{ 'screens.403.message.4.2'  | translate }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                &nbsp;
                                <ul>
                                    <li>
                                        <h3>{{ 'screens.403.message.footer' | translate }}</h3>
                                    </li>
                                </ul>
                                &nbsp;
                                <span>
                                    <button class="mr-2 m-lg-2" euiButton euiSizeS euiBasicButton euiOutline euiPrimary (click)="reload()">
                                        <span euiLabel>{{'screens.403.reload.label' | translate}}</span>
                                    </button>
                                    <button *ngIf="authenticated" class="mr-2 m-lg-2" euiButton euiSizeS euiBasicButton euiOutline euiPrimary (click)="signOut()">
                                        <span euiLabel>{{'screens.403.sign.out.label' | translate}}</span>
                                    </button>
                                </span>
                            </eui-card-content>
                        </eui-card>
                    </div>
                </eui-block-content>
            </ng-container>

        </eui-app-page-wrapper>

        <eui-app-footer>
            <eui-footer>
                <div class="col-12 text-center">
                    <div class="flex-container justify-content-around">
                        <span>
                            <span *ngIf="appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong> - {{ appReleaseDate }}</span>
                            <span *ngIf="!appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong></span>
                            <span> | <a *ngIf="privacyStatementLink.url" target="_blank" rel="noopener"
                                   href="{{ privacyStatementLink.url }}">{{ privacyStatementLink.label | translate }}
                                     </a>
                            </span>
                        </span>
                    </div>
                </div>
            </eui-footer>
        </eui-app-footer>

    </eui-app>

    <ng-container *ngIf="isIE11">
        <div>
            <strong>{{ 'screens.common.warning.label' | translate }} </strong> - {{
            'screens.common.obsolete.browser.message' | translate }}
        </div>
    </ng-container>

    <ng-template #forwarding>
        <eui-block-content>
            <p>{{ 'screens.403.forwarding.label' | translate }}...</p>
        </eui-block-content>
    </ng-template>
</div>
