import { Injectable } from '@angular/core';

import { Permission, Role, RolePermissions, SystemUser } from '../../shared/models';
import { AWSAuthService } from './aws-auth.service';
import { isEmpty } from '../helpers/null-value.util';
import { User } from '../../shared/models/user/user';
import { OperatorDetails } from '../../shared/models/operators/operator-details';
import { Observable, ReplaySubject } from 'rxjs';
import { CoreRefDataService } from './core-refdata.service';
import { map } from 'rxjs/operators';
import { SessionStorageService } from '@eui/core';

export const xOperatorId = 'x-operator-id';

@Injectable({ providedIn: 'root' })
export class UserService {

    private systemUser: SystemUser;
    private selfUser$: Observable<User>;
    private systemUserSubject$ = new ReplaySubject<SystemUser>();

    public constructor(
        private authService: AWSAuthService,
        private сoreRefDataService: CoreRefDataService,
        private storageService: SessionStorageService,
    ) {
      this.systemUser = undefined;

      this.selfUser$ = this.сoreRefDataService.refData$.pipe(
        map((coreRefData) => <User>{
          ...coreRefData.user,
        }),
      );
      this.subscribeOnGetSelfUser();
    }

    public onSystemUserUpdate(): Observable<SystemUser> {
      return this.systemUserSubject$.asObservable();
    }

    public getSelf(): Observable<User> {
      return this.selfUser$;
    }

    public isAuthorized(): boolean {
      return this.getSystemUser() && this.systemUser.roles && this.systemUser.roles.length > 0;
    }

    public isAuthenticated(): boolean {
      return !isEmpty(this.getSystemUser());
    }

    public getId(): string {
      return this.getSystemUser() && this.systemUser.userId;
    }

    public getRoles(): Role[] {
      return this.getSystemUser() && this.systemUser.roles;
    }

    public getPermissions(): Permission[] {
      return this.getSystemUser() && this.systemUser.permissions;
    }

    public updatePermissions() {
      if (this.getSystemUser()) {
        this.systemUser.permissions = this.systemUser.roles && this.systemUser.roles.reduce(
          (roles, role) => [...roles, ...RolePermissions.get(role)], [],
        );
      }
    }

    public hasRoles(roles: Role[], except?: Role[]): boolean {
      const userRoles = this.getRoles();
      return roles && userRoles && roles.some(role => userRoles.includes(role)) &&
            !(except && except.some(role => userRoles.includes(role)));
    }

    public hasPermissions(permissions: Permission[], except?: Permission[]): boolean {
      const userPermissions = this.getPermissions();
      return permissions && userPermissions && permissions.some(permission => userPermissions.includes(permission)) &&
            !(except && except.some(permission => userPermissions.includes(permission)));
    }

    public getSystemUser(): SystemUser {
      if (!this.systemUser) {
        const idtoken = this.authService.getIdTokenPayload();
        if (!isEmpty(idtoken)) {
          const firstName = idtoken.given_name || idtoken.name;
          this.updateSystemUser( {
            userId: this.authService.getUsername(),
            firstName: firstName,
            lastName: idtoken.family_name,
            fullName: !isEmpty(firstName) ? `${idtoken.given_name || idtoken.name} ${idtoken.family_name}` : '',
            email: idtoken.email,
            phone: idtoken.phone_number,
            operatorId: undefined,
            operatorName: undefined,
            ...this.mapRoles(idtoken && <Role[]>idtoken['cognito:groups']),
          }, false);
        }

      }
      return this.systemUser;
    }

    public updateSystemUser(systemUser: SystemUser, send: boolean) {
      this.systemUser = systemUser;
      if (!isEmpty(systemUser?.operatorId)) {
        this.storageService.set(xOperatorId, systemUser.operatorId);
      }

      if(send) {
        this.sendSystemUser();
      }
    }

    public mapRoles(cognitoGroups: Role[]): {
        roles: Role[]; permissions: Permission[];
    } {
      const roleTypeValues: Role[] = Object.values(Role);

      const roles = cognitoGroups && cognitoGroups.filter(
        group => roleTypeValues.includes(group),
      );
      const permissions = roles && roles.reduce(
        (roleMap, role) => [...roleMap, ...RolePermissions.get(role)], [],
      );

      return {
        roles,
        permissions,
      };
    }

    private sendSystemUser() {
      this.systemUserSubject$.next(this.systemUser);
    }

    private subscribeOnGetSelfUser() {
      this.getSelf().subscribe(user => {
        if (user?.registered === false) {
            this.updateSystemUser({
                ...this.getSystemUser(),
                registered: false,
              }, true);
        } else if (!isEmpty(user) && Object.keys(user).length > 0) {
          const operator = this.selectOperator(user.operatorDetails);
          this.updateSystemUser({
            ...user,
            fullName: !isEmpty(user.firstName) ? `${user.firstName} ${user.lastName}` : '',
            operatorId: operator ? operator.id : undefined,
            operatorName: operator ?  operator.name : '',
            ...this.mapRoles(<Role[]>[user.role]),
          }, true);
        }
      });
    }

    private selectOperator(operators: OperatorDetails[] | undefined): OperatorDetails | undefined {
        if (operators?.length === 1) {
            return operators[0];
        }
        const operatorId = this.storageService.get(xOperatorId);
        return operators?.find(o => o.id === operatorId);
    }
}
