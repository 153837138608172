<p-pickList
    #pPickList
    sourceHeader="{{ 'screens.declarations.form.picklist.vehicle.plate.number.available' | translate }} ({{ sourceValues?.length }})"
    targetHeader="{{ 'screens.declarations.form.picklist.vehicle.plate.number.selected' | translate }} ({{ selectedValues?.length }})"
    [source]="sourceValues"
    [target]="selectedValues"
    [responsive]="true"
    [showSourceControls]="false"
    [showTargetControls]="false"
    filterBy="label"
    sourceFilterPlaceholder="{{ 'screens.declarations.search.placeholder' | translate }}"
    targetFilterPlaceholder="{{ 'screens.declarations.search.placeholder' | translate }}"
    (onMoveToTarget)="addSelected($event)"
    (onMoveAllToTarget)="addSelected($event)"
    (onMoveToSource)="removedSelected($event)"
    (onMoveAllToSource)="removedSelected($event)">
        <ng-template let-value pTemplate="item">
            <div>{{value.label}}</div>
        </ng-template>
</p-pickList>

<div class="my-2">
    <input class="eui-input-text" placeholder="{{ 'screens.declarations.form.picklist.vehicle.plate.number.placeholder' | translate }}"
        value="" #inputValue (keyup.enter)="addMoreToSelected(inputValue.value); inputValue.value = ''"/>
</div>
<button euiButton euiOutline euiPrimary class="rtpd-addon-button" (click)="addMoreToSelected(inputValue.value); inputValue.value = ''">
    <span euiIcon iconClass="eui-icon eui-icon-add"></span>
    <span euiLabel>{{ 'screens.declarations.form.picklist.vehicle.plate.number.button' | translate }}</span>
</button>
